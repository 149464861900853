var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "div",
        { staticClass: "qrbox_design" },
        [
          _c("v-card-title", [
            _vm._v(_vm._s(_vm.$t("message.mixed.scanQRCode"))),
          ]),
          _c(
            "v-avatar",
            {
              on: {
                click: function ($event) {
                  return _vm.scanningVD()
                },
              },
            },
            [
              _c("v-icon", { attrs: { color: "grey darken-3", size: "33" } }, [
                _vm._v("mdi-qrcode-scan"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-title",
        [
          _c("barcode-scanner", {
            attrs: {
              search: _vm.scanCode,
              scannerPayload: _vm.scannerPayload,
              loading: _vm.respectiveViewLoading,
            },
            model: {
              value: _vm.scanCode,
              callback: function ($$v) {
                _vm.scanCode = $$v
              },
              expression: "scanCode",
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "mt-0",
          attrs: { persistent: "", "max-width": "500px" },
          model: {
            value: _vm.scanDialog,
            callback: function ($$v) {
              _vm.scanDialog = $$v
            },
            expression: "scanDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(" " + _vm._s(_vm.$t("message.common.scan")) + " "),
              ]),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "pa-1" }, [
                _vm._v(" " + _vm._s(_vm.scanError) + " "),
                _c("div", [
                  _c("video", {
                    ref: "video",
                    staticStyle: {
                      border: "1px solid gray",
                      width: "100%",
                      height: "auto",
                    },
                    attrs: { id: "video" },
                  }),
                ]),
                _vm.videoInputDevices.length > 1
                  ? _c(
                      "div",
                      { attrs: { id: "sourceSelectPanel" } },
                      [
                        _c("v-select", {
                          attrs: {
                            id: "sourceSelect",
                            outlined: "",
                            items: _vm.videoInputDevices,
                            "hide-details": "",
                            "item-text": "label",
                            "item-value": "deviceId",
                            label: _vm.$t("message.common.changeVideoSource"),
                          },
                          model: {
                            value: _vm.selectedDeviceId,
                            callback: function ($$v) {
                              _vm.selectedDeviceId = $$v
                            },
                            expression: "selectedDeviceId",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", id: "scanClose" },
                      on: { click: _vm.stopScanningSession },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.close")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.renderScrollList
        ? _c(
            "v-card-text",
            { staticClass: "pa-2 mt-2 mb-4" },
            [
              (_vm.scrollListPayload.itemHeaders &&
                _vm.scrollListPayload.list &&
                _vm.scrollListPayload.list.length &&
                _vm.$vuetify.breakpoint.name == "sm") ||
              _vm.$vuetify.breakpoint.name == "md" ||
              _vm.$vuetify.breakpoint.name == "lg" ||
              _vm.$vuetify.breakpoint.name == "xl"
                ? _c(
                    "v-card",
                    {
                      staticClass: "pa-0 mt-0",
                      attrs: { outlined: "", color: "#e8eef0" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _vm._l(
                            _vm.scrollListPayload.itemHeaders,
                            function (header, index) {
                              return [
                                _c(
                                  "v-col",
                                  {
                                    key: index,
                                    class:
                                      _vm.scrollListPayload.items[index].class,
                                    attrs: {
                                      cols: _vm.scrollListPayload.items[index]
                                        .cols,
                                    },
                                  },
                                  [
                                    header ===
                                      "message.projects.drawingGroup" &&
                                    _vm.scrollListPayload.advanceDrawingTypes
                                      ? _c("strong", [
                                          _vm._v(_vm._s(_vm.$t(header))),
                                        ])
                                      : header !==
                                        "message.projects.drawingGroup"
                                      ? _c("strong", [
                                          _vm._v(_vm._s(_vm.$t(header))),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-virtual-scroll", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.scrollListPayload.list.length > 0,
                    expression: "scrollListPayload.list.length > 0",
                  },
                ],
                attrs: {
                  items: _vm.scrollListPayload.list,
                  "item-height": _vm.getItemHeight("renderScrollList"),
                  "max-height": "95vh",
                  title: _vm.scrollListPayload.list.length,
                },
                scopedSlots: _vm._u(
                  [
                    _vm.$vuetify.breakpoint.name == "sm" ||
                    _vm.$vuetify.breakpoint.name == "md" ||
                    _vm.$vuetify.breakpoint.name == "lg" ||
                    _vm.$vuetify.breakpoint.name == "xl"
                      ? {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-1 mt-1",
                                  staticStyle: { overflow: "hidden" },
                                  attrs: { outlined: "", color: "#e8eaf6" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass: "pa-1",
                                      class:
                                        _vm.$t(
                                          _vm.scrollListPayload.moduleTitle
                                        ) ===
                                          _vm.$t("message.layout.drawing") &&
                                        item &&
                                        item.is_completed === 5
                                          ? "orange--text text--lighten-2"
                                          : "",
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          [
                                            _vm._l(
                                              _vm.scrollListPayload.items,
                                              function (colValues, index) {
                                                return [
                                                  colValues.value
                                                    ? [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: index,
                                                            class:
                                                              colValues.class,
                                                            attrs: {
                                                              cols: colValues.cols,
                                                            },
                                                          },
                                                          [
                                                            colValues.isParagraph
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "custom-height",
                                                                    class:
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ] &&
                                                                      colValues.value ==
                                                                        "description" &&
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ].length >
                                                                        100
                                                                        ? "d-inline-block"
                                                                        : "",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item[
                                                                            colValues
                                                                              .value
                                                                          ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    class:
                                                                      colValues.spanClass,
                                                                  },
                                                                  [
                                                                    colValues.label
                                                                      ? _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                colValues.label
                                                                              ) +
                                                                                ": "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    colValues.label
                                                                      ? _c("br")
                                                                      : _vm._e(),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item[
                                                                          colValues
                                                                            .value
                                                                        ]
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        ),
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: index,
                                                            class:
                                                              colValues.class,
                                                            attrs: {
                                                              cols: colValues.cols,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                class:
                                                                  colValues.rowClass,
                                                              },
                                                              [
                                                                _vm._l(
                                                                  colValues.subItems,
                                                                  function (
                                                                    subitems,
                                                                    i
                                                                  ) {
                                                                    return [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          key: i,
                                                                          class:
                                                                            subitems.class,
                                                                          attrs:
                                                                            {
                                                                              cols: subitems.cols,
                                                                            },
                                                                        },
                                                                        [
                                                                          subitems.value ===
                                                                          "is_completed"
                                                                            ? [
                                                                                _vm
                                                                                  .scrollListPayload
                                                                                  .moduleTitle ===
                                                                                "message.layout.drawing"
                                                                                  ? _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "px-0 pr-2",
                                                                                        attrs:
                                                                                          {
                                                                                            dense:
                                                                                              "",
                                                                                            "hide-details":
                                                                                              "",
                                                                                            "item-text":
                                                                                              "text",
                                                                                            "item-value":
                                                                                              "value",
                                                                                            items:
                                                                                              subitems.items,
                                                                                            label:
                                                                                              "Status",
                                                                                            outlined:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.stopPropagation()
                                                                                            },
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.saveStatusUpdate(
                                                                                                _vm.scrollListPayload.list.indexOf(
                                                                                                  item
                                                                                                )
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              item[
                                                                                                subitems
                                                                                                  .value
                                                                                              ],
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  item,
                                                                                                  subitems.value,
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "item[subitems.value]",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : [
                                                                                      !_vm
                                                                                        .scrollListPayload
                                                                                        .itemHeaders
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              class:
                                                                                                subitems.spanClass,
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "strong",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "message.projects.status"
                                                                                                      )
                                                                                                    ) +
                                                                                                      ":"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      !_vm
                                                                                        .scrollListPayload
                                                                                        .itemHeaders
                                                                                        ? _c(
                                                                                            "br"
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          class:
                                                                                            subitems.spanClass,
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item[
                                                                                                subitems
                                                                                                  .value
                                                                                              ] ===
                                                                                                1
                                                                                                ? _vm.$t(
                                                                                                    "message.customModule.completed"
                                                                                                  )
                                                                                                : _vm.$t(
                                                                                                    "message.customModule.notCompleted"
                                                                                                  )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                              ]
                                                                            : subitems.isTranslatable
                                                                            ? [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      subitems.spanClass,
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          item[
                                                                                            subitems
                                                                                              .value
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            : _c(
                                                                                "span",
                                                                                {
                                                                                  class:
                                                                                    subitems.spanClass,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                ]
                                              }
                                            ),
                                          ],
                                          _vm.scrollListPayload.actionSize
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0 pr-1 mt-3",
                                                  attrs: {
                                                    cols: _vm.scrollListPayload
                                                      .actionSize,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      staticClass: "mx-0",
                                                      attrs: {
                                                        row: "",
                                                        wrap: "",
                                                        "justify-end": "",
                                                      },
                                                    },
                                                    [
                                                      !_vm.scrollListPayload
                                                        .isChecklist &&
                                                      _vm.scrollListPayload
                                                        .hasDelete &&
                                                      _vm.checkActionPermission(
                                                        _vm.scrollListPayload
                                                          .module,
                                                        _vm.CONSTANTS.DELETE
                                                      )
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                "max-height":
                                                                  "24",
                                                                "max-width":
                                                                  "24",
                                                                icon: "",
                                                                color: "error",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.deleteRecord(
                                                                      item.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-delete-circle"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        }
                      : {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-0 mt-2  overflow-x-hidden",
                                  attrs: { outlined: "", color: "#e8eaf6" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0 px-4 my-2" },
                                    [
                                      _vm._l(
                                        _vm.scrollListPayload.items,
                                        function (colValues, index) {
                                          return [
                                            _c(
                                              "v-row",
                                              {
                                                key: index,
                                                staticClass: "pt-1",
                                              },
                                              [
                                                _vm.scrollListPayload
                                                  .itemHeaders &&
                                                _vm.scrollListPayload
                                                  .itemHeaders.length
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pa-0",
                                                        attrs: {
                                                          cols: !_vm
                                                            .scrollListPayload
                                                            .hasDelete
                                                            ? "4"
                                                            : "3",
                                                        },
                                                      },
                                                      [
                                                        _vm.scrollListPayload
                                                          .itemHeaders[
                                                          index
                                                        ] ===
                                                          "message.projects.drawingGroup" &&
                                                        _vm.scrollListPayload
                                                          .advanceDrawingTypes
                                                          ? _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm
                                                                      .scrollListPayload
                                                                      .itemHeaders[
                                                                      index
                                                                    ]
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm
                                                              .scrollListPayload
                                                              .itemHeaders[
                                                              index
                                                            ] !==
                                                            "message.projects.drawingGroup"
                                                          ? _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm
                                                                      .scrollListPayload
                                                                      .itemHeaders[
                                                                      index
                                                                    ]
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-0",
                                                    class:
                                                      _vm.$t(
                                                        _vm.scrollListPayload
                                                          .moduleTitle
                                                      ) ===
                                                        _vm.$t(
                                                          "message.layout.drawing"
                                                        ) &&
                                                      item &&
                                                      item.is_completed === 5
                                                        ? "orange--text text--lighten-2"
                                                        : "",
                                                    attrs: {
                                                      cols: !_vm
                                                        .scrollListPayload
                                                        .hasDelete
                                                        ? "8"
                                                        : "8",
                                                    },
                                                  },
                                                  [
                                                    colValues.value
                                                      ? [
                                                          colValues.isParagraph
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item[
                                                                          colValues
                                                                            .value
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                  class:
                                                                    colValues.spanClass,
                                                                },
                                                                [
                                                                  colValues.label
                                                                    ? _c(
                                                                        "strong",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              colValues.label
                                                                            ) +
                                                                              ": "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  colValues.label
                                                                    ? _c("br")
                                                                    : _vm._e(),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ]
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      : [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              class:
                                                                colValues.rowClass,
                                                            },
                                                            [
                                                              _vm._l(
                                                                colValues.subItems,
                                                                function (
                                                                  subitems,
                                                                  i
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        key: i,
                                                                        class:
                                                                          subitems.class,
                                                                        attrs: {
                                                                          cols: subitems.cols,
                                                                        },
                                                                      },
                                                                      [
                                                                        subitems.value ===
                                                                        "is_completed"
                                                                          ? [
                                                                              _vm
                                                                                .scrollListPayload
                                                                                .moduleTitle ===
                                                                              "message.layout.drawing"
                                                                                ? _c(
                                                                                    "v-select",
                                                                                    {
                                                                                      staticClass:
                                                                                        "px-0 pr-2",
                                                                                      attrs:
                                                                                        {
                                                                                          dense:
                                                                                            "",
                                                                                          "hide-details":
                                                                                            "",
                                                                                          "item-text":
                                                                                            "text",
                                                                                          "item-value":
                                                                                            "value",
                                                                                          items:
                                                                                            subitems.items,
                                                                                          label:
                                                                                            "Status",
                                                                                          outlined:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                          },
                                                                                        change:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.saveStatusUpdate(
                                                                                              _vm.scrollListPayload.list.indexOf(
                                                                                                item
                                                                                              )
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            item[
                                                                                              subitems
                                                                                                .value
                                                                                            ],
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                item,
                                                                                                subitems.value,
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "item[subitems.value]",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : [
                                                                                    !_vm
                                                                                      .scrollListPayload
                                                                                      .itemHeaders
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "mx-2",
                                                                                            class:
                                                                                              subitems.spanClass,
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "strong",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "message.projects.status"
                                                                                                    )
                                                                                                  ) +
                                                                                                    ":"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    !_vm
                                                                                      .scrollListPayload
                                                                                      .itemHeaders
                                                                                      ? _c(
                                                                                          "br"
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mx-2",
                                                                                        class:
                                                                                          subitems.spanClass,
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            item[
                                                                                              subitems
                                                                                                .value
                                                                                            ] ===
                                                                                              1
                                                                                              ? _vm.$t(
                                                                                                  "message.customModule.completed"
                                                                                                )
                                                                                              : _vm.$t(
                                                                                                  "message.customModule.notCompleted"
                                                                                                )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                            ]
                                                                          : subitems.isTranslatable
                                                                          ? [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-2",
                                                                                  class:
                                                                                    subitems.spanClass,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          : _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-2",
                                                                                class:
                                                                                  subitems.spanClass,
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      item[
                                                                                        subitems
                                                                                          .value
                                                                                      ]
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ]
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              staticClass: "mx-0 mr-5",
                                              attrs: {
                                                row: "",
                                                wrap: "",
                                                "justify-end": "",
                                              },
                                            },
                                            [
                                              !_vm.scrollListPayload
                                                .isChecklist &&
                                              _vm.scrollListPayload.hasDelete &&
                                              _vm.checkActionPermission(
                                                _vm.scrollListPayload.module,
                                                _vm.CONSTANTS.DELETE
                                              )
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        color: "error",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.deleteRecord(
                                                            item.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { large: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-delete-circle"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                  ],
                  null,
                  true
                ),
              }),
              _c(
                "p",
                { staticClass: "text-center px-5 py-0" },
                [
                  _vm.scrollListPayload.loading
                    ? _c("v-progress-linear", {
                        attrs: {
                          color: "deep-purple accent-4",
                          indeterminate: "",
                          rounded: "",
                          height: "6",
                        },
                      })
                    : _vm._e(),
                  false
                    ? _c("v-progress-circular", {
                        staticClass: "mx-auto",
                        attrs: { indeterminate: "", color: "primary" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              !_vm.scrollListPayload.loading &&
              _vm.scrollListPayload.list.length === 0
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-2",
                      attrs: { prominent: "", text: "", type: "info" },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { staticClass: "grow" }, [
                            _vm._v(_vm._s(_vm.$t("message.common.noResults"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.supplierScrollList
        ? _c(
            "v-card-text",
            { staticClass: "pa-2 mt-2 mb-4" },
            [
              _c("h4", { staticClass: "headingdesign" }, [
                _vm._v(_vm._s(_vm.$t("message.layout.orders"))),
              ]),
              (_vm.supplierorders.itemHeaders &&
                _vm.supplierorders.list &&
                _vm.supplierorders.list.length &&
                _vm.$vuetify.breakpoint.name == "sm") ||
              _vm.$vuetify.breakpoint.name == "md" ||
              _vm.$vuetify.breakpoint.name == "lg" ||
              _vm.$vuetify.breakpoint.name == "xl"
                ? _c(
                    "v-card",
                    {
                      staticClass: "pa-0 mt-0 ",
                      attrs: { outlined: "", color: "#e8eef0" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _vm._l(
                            _vm.supplierorders.itemHeaders,
                            function (header, index) {
                              return [
                                _c(
                                  "v-col",
                                  {
                                    key: index,
                                    class:
                                      _vm.supplierorders.items[index].class,
                                    attrs: {
                                      cols: _vm.supplierorders.items[index]
                                        .cols,
                                    },
                                  },
                                  [
                                    header ===
                                      "message.projects.drawingGroup" &&
                                    _vm.supplierorders.advanceDrawingTypes
                                      ? _c("strong", [
                                          _vm._v(_vm._s(_vm.$t(header))),
                                        ])
                                      : header !==
                                        "message.projects.drawingGroup"
                                      ? _c("strong", [
                                          _vm._v(_vm._s(_vm.$t(header))),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-virtual-scroll", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.supplierorders.list.length > 0,
                    expression: "supplierorders.list.length > 0",
                  },
                ],
                attrs: {
                  items: _vm.supplierorders.list,
                  "item-height": _vm.getItemHeight("supplierScrollList"),
                  "max-height": "95vh",
                  title: _vm.supplierorders.list.length,
                },
                scopedSlots: _vm._u(
                  [
                    _vm.$vuetify.breakpoint.name == "sm" ||
                    _vm.$vuetify.breakpoint.name == "md" ||
                    _vm.$vuetify.breakpoint.name == "lg" ||
                    _vm.$vuetify.breakpoint.name == "xl"
                      ? {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-1 mt-1",
                                  staticStyle: { overflow: "hidden" },
                                  attrs: { outlined: "", color: "#e8eaf6" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.orderdatalistRoute(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass: "pa-1",
                                      class:
                                        _vm.$t(
                                          _vm.supplierorders.moduleTitle
                                        ) ===
                                          _vm.$t("message.layout.drawing") &&
                                        item &&
                                        item.is_completed === 5
                                          ? "orange--text text--lighten-2"
                                          : "",
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          [
                                            _vm._l(
                                              _vm.supplierorders.items,
                                              function (colValues, index) {
                                                return [
                                                  colValues.value
                                                    ? [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: index,
                                                            class:
                                                              colValues.class,
                                                            attrs: {
                                                              cols: colValues.cols,
                                                            },
                                                          },
                                                          [
                                                            colValues.isParagraph
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "custom-height",
                                                                    class:
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ] &&
                                                                      colValues.value ==
                                                                        "description" &&
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ].length >
                                                                        100
                                                                        ? "d-inline-block"
                                                                        : "",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item[
                                                                            colValues
                                                                              .value
                                                                          ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    class:
                                                                      colValues.spanClass,
                                                                  },
                                                                  [
                                                                    colValues.label
                                                                      ? _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                colValues.label
                                                                              ) +
                                                                                ": "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    colValues.label
                                                                      ? _c("br")
                                                                      : _vm._e(),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item[
                                                                          colValues
                                                                            .value
                                                                        ]
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        ),
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: index,
                                                            class:
                                                              colValues.class,
                                                            attrs: {
                                                              cols: colValues.cols,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                class:
                                                                  colValues.rowClass,
                                                              },
                                                              [
                                                                _vm._l(
                                                                  colValues.subItems,
                                                                  function (
                                                                    subitems,
                                                                    i
                                                                  ) {
                                                                    return [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          key: i,
                                                                          class:
                                                                            subitems.class,
                                                                          attrs:
                                                                            {
                                                                              cols: subitems.cols,
                                                                            },
                                                                        },
                                                                        [
                                                                          subitems.value ===
                                                                          "is_completed"
                                                                            ? [
                                                                                _vm
                                                                                  .scrollListPayload
                                                                                  .moduleTitle ===
                                                                                "message.layout.drawing"
                                                                                  ? _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "px-0 pr-2",
                                                                                        attrs:
                                                                                          {
                                                                                            dense:
                                                                                              "",
                                                                                            "hide-details":
                                                                                              "",
                                                                                            "item-text":
                                                                                              "text",
                                                                                            "item-value":
                                                                                              "value",
                                                                                            items:
                                                                                              subitems.items,
                                                                                            label:
                                                                                              "Status",
                                                                                            outlined:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.stopPropagation()
                                                                                            },
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.saveStatusUpdate(
                                                                                                _vm.scrollListPayload.list.indexOf(
                                                                                                  item
                                                                                                )
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              item[
                                                                                                subitems
                                                                                                  .value
                                                                                              ],
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  item,
                                                                                                  subitems.value,
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "item[subitems.value]",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : [
                                                                                      !_vm
                                                                                        .scrollListPayload
                                                                                        .itemHeaders
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              class:
                                                                                                subitems.spanClass,
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "strong",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "message.projects.status"
                                                                                                      )
                                                                                                    ) +
                                                                                                      ":"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      !_vm
                                                                                        .scrollListPayload
                                                                                        .itemHeaders
                                                                                        ? _c(
                                                                                            "br"
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          class:
                                                                                            subitems.spanClass,
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item[
                                                                                                subitems
                                                                                                  .value
                                                                                              ] ===
                                                                                                1
                                                                                                ? _vm.$t(
                                                                                                    "message.customModule.completed"
                                                                                                  )
                                                                                                : _vm.$t(
                                                                                                    "message.customModule.notCompleted"
                                                                                                  )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                              ]
                                                                            : subitems.isTranslatable
                                                                            ? [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      subitems.spanClass,
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          item[
                                                                                            subitems
                                                                                              .value
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            : _c(
                                                                                "span",
                                                                                {
                                                                                  class:
                                                                                    subitems.spanClass,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                ]
                                              }
                                            ),
                                          ],
                                          _vm.supplierorders.actionSize
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0 pr-1 mt-3",
                                                  attrs: {
                                                    cols: _vm.supplierorders
                                                      .actionSize,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      staticClass: "mx-0",
                                                      attrs: {
                                                        row: "",
                                                        wrap: "",
                                                        "justify-end": "",
                                                      },
                                                    },
                                                    [
                                                      !_vm.supplierorders
                                                        .isChecklist &&
                                                      _vm.supplierorders
                                                        .hasDelete &&
                                                      _vm.checkActionPermission(
                                                        _vm.scrollListPayload
                                                          .module,
                                                        _vm.CONSTANTS.DELETE
                                                      )
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                "max-height":
                                                                  "24",
                                                                "max-width":
                                                                  "24",
                                                                icon: "",
                                                                color: "error",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.deleteRecord(
                                                                      item.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-delete-circle"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        }
                      : {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-0 mt-2  overflow-x-hidden",
                                  attrs: {
                                    outlined: "",
                                    "min-height": "150",
                                    color: "#e8eaf6",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.checkActionPermission(
                                        _vm.supplierorders.module,
                                        _vm.CONSTANTS.EDIT
                                      )
                                        ? _vm.supplierorders.editHandler(item)
                                        : ""
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0 px-4 my-2" },
                                    [
                                      _vm._l(
                                        _vm.supplierorders.items,
                                        function (colValues, index) {
                                          return [
                                            _c(
                                              "v-row",
                                              {
                                                key: index,
                                                staticClass: "pt-1",
                                              },
                                              [
                                                _vm.scrollListPayload
                                                  .itemHeaders &&
                                                _vm.scrollListPayload
                                                  .itemHeaders.length
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pa-0",
                                                        attrs: {
                                                          cols: !_vm
                                                            .scrollListPayload
                                                            .hasDelete
                                                            ? "4"
                                                            : "3",
                                                        },
                                                      },
                                                      [
                                                        _vm.scrollListPayload
                                                          .itemHeaders[
                                                          index
                                                        ] ===
                                                          "message.projects.drawingGroup" &&
                                                        _vm.scrollListPayload
                                                          .advanceDrawingTypes
                                                          ? _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm
                                                                      .scrollListPayload
                                                                      .itemHeaders[
                                                                      index
                                                                    ]
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm
                                                              .scrollListPayload
                                                              .itemHeaders[
                                                              index
                                                            ] !==
                                                            "message.projects.drawingGroup"
                                                          ? _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm
                                                                      .scrollListPayload
                                                                      .itemHeaders[
                                                                      index
                                                                    ]
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-0",
                                                    class:
                                                      _vm.$t(
                                                        _vm.scrollListPayload
                                                          .moduleTitle
                                                      ) ===
                                                        _vm.$t(
                                                          "message.layout.drawing"
                                                        ) &&
                                                      item &&
                                                      item.is_completed === 5
                                                        ? "orange--text text--lighten-2"
                                                        : "",
                                                    attrs: {
                                                      cols: !_vm
                                                        .scrollListPayload
                                                        .hasDelete
                                                        ? "8"
                                                        : "8",
                                                    },
                                                  },
                                                  [
                                                    colValues.value
                                                      ? [
                                                          colValues.isParagraph
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item[
                                                                          colValues
                                                                            .value
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                  class:
                                                                    colValues.spanClass,
                                                                },
                                                                [
                                                                  colValues.label
                                                                    ? _c(
                                                                        "strong",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              colValues.label
                                                                            ) +
                                                                              ": "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  colValues.label
                                                                    ? _c("br")
                                                                    : _vm._e(),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ]
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      : [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              class:
                                                                colValues.rowClass,
                                                            },
                                                            [
                                                              _vm._l(
                                                                colValues.subItems,
                                                                function (
                                                                  subitems,
                                                                  i
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        key: i,
                                                                        class:
                                                                          subitems.class,
                                                                        attrs: {
                                                                          cols: subitems.cols,
                                                                        },
                                                                      },
                                                                      [
                                                                        subitems.value ===
                                                                        "is_completed"
                                                                          ? [
                                                                              _vm
                                                                                .scrollListPayload
                                                                                .moduleTitle ===
                                                                              "message.layout.drawing"
                                                                                ? _c(
                                                                                    "v-select",
                                                                                    {
                                                                                      staticClass:
                                                                                        "px-0 pr-2",
                                                                                      attrs:
                                                                                        {
                                                                                          dense:
                                                                                            "",
                                                                                          "hide-details":
                                                                                            "",
                                                                                          "item-text":
                                                                                            "text",
                                                                                          "item-value":
                                                                                            "value",
                                                                                          items:
                                                                                            subitems.items,
                                                                                          label:
                                                                                            "Status",
                                                                                          outlined:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                          },
                                                                                        change:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.saveStatusUpdate(
                                                                                              _vm.scrollListPayload.list.indexOf(
                                                                                                item
                                                                                              )
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            item[
                                                                                              subitems
                                                                                                .value
                                                                                            ],
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                item,
                                                                                                subitems.value,
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "item[subitems.value]",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : [
                                                                                    !_vm
                                                                                      .scrollListPayload
                                                                                      .itemHeaders
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "mx-2",
                                                                                            class:
                                                                                              subitems.spanClass,
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "strong",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "message.projects.status"
                                                                                                    )
                                                                                                  ) +
                                                                                                    ":"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    !_vm
                                                                                      .scrollListPayload
                                                                                      .itemHeaders
                                                                                      ? _c(
                                                                                          "br"
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mx-2",
                                                                                        class:
                                                                                          subitems.spanClass,
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            item[
                                                                                              subitems
                                                                                                .value
                                                                                            ] ===
                                                                                              1
                                                                                              ? _vm.$t(
                                                                                                  "message.customModule.completed"
                                                                                                )
                                                                                              : _vm.$t(
                                                                                                  "message.customModule.notCompleted"
                                                                                                )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                            ]
                                                                          : subitems.isTranslatable
                                                                          ? [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-2",
                                                                                  class:
                                                                                    subitems.spanClass,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          : _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-2",
                                                                                class:
                                                                                  subitems.spanClass,
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      item[
                                                                                        subitems
                                                                                          .value
                                                                                      ]
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ]
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              staticClass: "mx-0 mr-5",
                                              attrs: {
                                                row: "",
                                                wrap: "",
                                                "justify-end": "",
                                              },
                                            },
                                            [
                                              !_vm.supplierorders.isChecklist &&
                                              _vm.supplierorders.hasDelete &&
                                              _vm.checkActionPermission(
                                                _vm.supplierorders.module,
                                                _vm.CONSTANTS.DELETE
                                              )
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        color: "error",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.deleteRecord(
                                                            item.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { large: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-delete-circle"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                  ],
                  null,
                  true
                ),
              }),
              _c(
                "p",
                { staticClass: "text-center px-5 py-0" },
                [
                  _vm.supplierorders.loading
                    ? _c("v-progress-linear", {
                        attrs: {
                          color: "deep-purple accent-4",
                          indeterminate: "",
                          rounded: "",
                          height: "6",
                        },
                      })
                    : _vm._e(),
                  false
                    ? _c("v-progress-circular", {
                        staticClass: "mx-auto",
                        attrs: { indeterminate: "", color: "primary" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              !_vm.supplierorders.loading &&
              _vm.supplierorders.list.length === 0
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-2",
                      attrs: { prominent: "", text: "", type: "info" },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { staticClass: "grow" }, [
                            _vm._v(_vm._s(_vm.$t("message.common.noResults"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.eqidataScrollList
        ? _c(
            "v-card-text",
            { staticClass: "pa-2 mt-2 mb-4" },
            [
              _c("h4", { staticClass: "headingdesign" }, [
                _vm._v(_vm._s(this.$t("message.layout.equipments"))),
              ]),
              (_vm.eqidata.itemHeaders &&
                _vm.eqidata.list &&
                _vm.eqidata.list.length &&
                _vm.$vuetify.breakpoint.name == "sm") ||
              _vm.$vuetify.breakpoint.name == "md" ||
              _vm.$vuetify.breakpoint.name == "lg" ||
              _vm.$vuetify.breakpoint.name == "xl"
                ? _c(
                    "v-card",
                    {
                      staticClass: "pa-0 mt-0 ",
                      attrs: { outlined: "", color: "#e8eef0" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _vm._l(
                            _vm.eqidata.itemHeaders,
                            function (header, index) {
                              return [
                                _c(
                                  "v-col",
                                  {
                                    key: index,
                                    class: _vm.eqidata.items[index].class,
                                    attrs: {
                                      cols: _vm.eqidata.items[index].cols,
                                    },
                                  },
                                  [
                                    header ===
                                      "message.projects.drawingGroup" &&
                                    _vm.eqidata.advanceDrawingTypes
                                      ? _c("strong", [
                                          _vm._v(_vm._s(_vm.$t(header))),
                                        ])
                                      : header !==
                                        "message.projects.drawingGroup"
                                      ? _c("strong", [
                                          _vm._v(_vm._s(_vm.$t(header))),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-virtual-scroll", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.eqidata.list.length > 0,
                    expression: "eqidata.list.length > 0",
                  },
                ],
                attrs: {
                  items: _vm.eqidata.list,
                  "item-height": _vm.getItemHeight("eqidataScrollList"),
                  title: _vm.eqidata.list.length,
                },
                scopedSlots: _vm._u(
                  [
                    _vm.$vuetify.breakpoint.name == "sm" ||
                    _vm.$vuetify.breakpoint.name == "md" ||
                    _vm.$vuetify.breakpoint.name == "lg" ||
                    _vm.$vuetify.breakpoint.name == "xl"
                      ? {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-1 mt-1",
                                  staticStyle: { overflow: "hidden" },
                                  attrs: { outlined: "", color: "#e8eaf6" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.equipmentdatalistRoute(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass: "pa-1",
                                      class:
                                        _vm.$t(_vm.eqidata.moduleTitle) ===
                                          _vm.$t("message.layout.drawing") &&
                                        item &&
                                        item.is_completed === 5
                                          ? "orange--text text--lighten-2"
                                          : "",
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          [
                                            _vm._l(
                                              _vm.eqidata.items,
                                              function (colValues, index) {
                                                return [
                                                  colValues.value
                                                    ? [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: index,
                                                            class:
                                                              colValues.class,
                                                            attrs: {
                                                              cols: colValues.cols,
                                                            },
                                                          },
                                                          [
                                                            colValues.isParagraph
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "custom-height",
                                                                    class:
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ] &&
                                                                      colValues.value ==
                                                                        "description" &&
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ].length >
                                                                        100
                                                                        ? "d-inline-block"
                                                                        : "",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item[
                                                                            colValues
                                                                              .value
                                                                          ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    class:
                                                                      colValues.spanClass,
                                                                  },
                                                                  [
                                                                    colValues.label
                                                                      ? _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                colValues.label
                                                                              ) +
                                                                                ": "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    colValues.label
                                                                      ? _c("br")
                                                                      : _vm._e(),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item[
                                                                          colValues
                                                                            .value
                                                                        ]
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        ),
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: index,
                                                            class:
                                                              colValues.class,
                                                            attrs: {
                                                              cols: colValues.cols,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                class:
                                                                  colValues.rowClass,
                                                              },
                                                              [
                                                                _vm._l(
                                                                  colValues.subItems,
                                                                  function (
                                                                    subitems,
                                                                    i
                                                                  ) {
                                                                    return [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          key: i,
                                                                          class:
                                                                            subitems.class,
                                                                          attrs:
                                                                            {
                                                                              cols: subitems.cols,
                                                                            },
                                                                        },
                                                                        [
                                                                          subitems.value ===
                                                                          "is_completed"
                                                                            ? [
                                                                                _vm
                                                                                  .eqidata
                                                                                  .moduleTitle ===
                                                                                "message.layout.drawing"
                                                                                  ? _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "px-0 pr-2",
                                                                                        attrs:
                                                                                          {
                                                                                            dense:
                                                                                              "",
                                                                                            "hide-details":
                                                                                              "",
                                                                                            "item-text":
                                                                                              "text",
                                                                                            "item-value":
                                                                                              "value",
                                                                                            items:
                                                                                              subitems.items,
                                                                                            label:
                                                                                              "Status",
                                                                                            outlined:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.stopPropagation()
                                                                                            },
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.saveStatusUpdate(
                                                                                                _vm.eqidata.list.indexOf(
                                                                                                  item
                                                                                                )
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              item[
                                                                                                subitems
                                                                                                  .value
                                                                                              ],
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  item,
                                                                                                  subitems.value,
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "item[subitems.value]",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : [
                                                                                      !_vm
                                                                                        .eqidata
                                                                                        .itemHeaders
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              class:
                                                                                                subitems.spanClass,
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "strong",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "message.projects.status"
                                                                                                      )
                                                                                                    ) +
                                                                                                      ":"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      !_vm
                                                                                        .eqidata
                                                                                        .itemHeaders
                                                                                        ? _c(
                                                                                            "br"
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          class:
                                                                                            subitems.spanClass,
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item[
                                                                                                subitems
                                                                                                  .value
                                                                                              ] ===
                                                                                                1
                                                                                                ? _vm.$t(
                                                                                                    "message.customModule.completed"
                                                                                                  )
                                                                                                : _vm.$t(
                                                                                                    "message.customModule.notCompleted"
                                                                                                  )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                              ]
                                                                            : subitems.isTranslatable
                                                                            ? [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      subitems.spanClass,
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          item[
                                                                                            subitems
                                                                                              .value
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            : _c(
                                                                                "span",
                                                                                {
                                                                                  class:
                                                                                    subitems.spanClass,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                ]
                                              }
                                            ),
                                          ],
                                          _vm.eqidata.actionSize
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0 pr-1 mt-3",
                                                  attrs: {
                                                    cols: _vm.eqidata
                                                      .actionSize,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      staticClass: "mx-0",
                                                      attrs: {
                                                        row: "",
                                                        wrap: "",
                                                        "justify-end": "",
                                                      },
                                                    },
                                                    [
                                                      !_vm.eqidata
                                                        .isChecklist &&
                                                      _vm.eqidata.hasDelete &&
                                                      _vm.checkActionPermission(
                                                        _vm.eqidata.module,
                                                        _vm.CONSTANTS.DELETE
                                                      )
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                "max-height":
                                                                  "24",
                                                                "max-width":
                                                                  "24",
                                                                icon: "",
                                                                color: "error",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.deleteRecord(
                                                                      item.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-delete-circle"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        }
                      : {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-0 mt-2  overflow-x-hidden",
                                  attrs: { outlined: "", color: "#e8eaf6" },
                                  on: {
                                    click: function ($event) {
                                      _vm.checkActionPermission(
                                        _vm.eqidata.module,
                                        _vm.CONSTANTS.EDIT
                                      )
                                        ? _vm.scrollListPayload.editHandler(
                                            item
                                          )
                                        : ""
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0 px-4 my-2" },
                                    [
                                      _vm._l(
                                        _vm.eqidata.items,
                                        function (colValues, index) {
                                          return [
                                            _c(
                                              "v-row",
                                              {
                                                key: index,
                                                staticClass: "pt-1",
                                              },
                                              [
                                                _vm.eqidata.itemHeaders &&
                                                _vm.eqidata.itemHeaders.length
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pa-0",
                                                        attrs: {
                                                          cols: !_vm.eqidata
                                                            .hasDelete
                                                            ? "4"
                                                            : "3",
                                                        },
                                                      },
                                                      [
                                                        _vm.eqidata.itemHeaders[
                                                          index
                                                        ] ===
                                                          "message.projects.drawingGroup" &&
                                                        _vm.eqidata
                                                          .advanceDrawingTypes
                                                          ? _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm.eqidata
                                                                      .itemHeaders[
                                                                      index
                                                                    ]
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm.eqidata
                                                              .itemHeaders[
                                                              index
                                                            ] !==
                                                            "message.projects.drawingGroup"
                                                          ? _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm.eqidata
                                                                      .itemHeaders[
                                                                      index
                                                                    ]
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-0",
                                                    class:
                                                      _vm.$t(
                                                        _vm.eqidata.moduleTitle
                                                      ) ===
                                                        _vm.$t(
                                                          "message.layout.drawing"
                                                        ) &&
                                                      item &&
                                                      item.is_completed === 5
                                                        ? "orange--text text--lighten-2"
                                                        : "",
                                                    attrs: {
                                                      cols: !_vm.eqidata
                                                        .hasDelete
                                                        ? "8"
                                                        : "8",
                                                    },
                                                  },
                                                  [
                                                    colValues.value
                                                      ? [
                                                          colValues.isParagraph
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item[
                                                                          colValues
                                                                            .value
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                  class:
                                                                    colValues.spanClass,
                                                                },
                                                                [
                                                                  colValues.label
                                                                    ? _c(
                                                                        "strong",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              colValues.label
                                                                            ) +
                                                                              ": "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  colValues.label
                                                                    ? _c("br")
                                                                    : _vm._e(),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ]
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      : [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              class:
                                                                colValues.rowClass,
                                                            },
                                                            [
                                                              _vm._l(
                                                                colValues.subItems,
                                                                function (
                                                                  subitems,
                                                                  i
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        key: i,
                                                                        class:
                                                                          subitems.class,
                                                                        attrs: {
                                                                          cols: subitems.cols,
                                                                        },
                                                                      },
                                                                      [
                                                                        subitems.value ===
                                                                        "is_completed"
                                                                          ? [
                                                                              _vm
                                                                                .eqidata
                                                                                .moduleTitle ===
                                                                              "message.layout.drawing"
                                                                                ? _c(
                                                                                    "v-select",
                                                                                    {
                                                                                      staticClass:
                                                                                        "px-0 pr-2",
                                                                                      attrs:
                                                                                        {
                                                                                          dense:
                                                                                            "",
                                                                                          "hide-details":
                                                                                            "",
                                                                                          "item-text":
                                                                                            "text",
                                                                                          "item-value":
                                                                                            "value",
                                                                                          items:
                                                                                            subitems.items,
                                                                                          label:
                                                                                            "Status",
                                                                                          outlined:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                          },
                                                                                        change:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.saveStatusUpdate(
                                                                                              _vm.eqidata.list.indexOf(
                                                                                                item
                                                                                              )
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            item[
                                                                                              subitems
                                                                                                .value
                                                                                            ],
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                item,
                                                                                                subitems.value,
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "item[subitems.value]",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : [
                                                                                    !_vm
                                                                                      .eqidata
                                                                                      .itemHeaders
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "mx-2",
                                                                                            class:
                                                                                              subitems.spanClass,
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "strong",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "message.projects.status"
                                                                                                    )
                                                                                                  ) +
                                                                                                    ":"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    !_vm
                                                                                      .eqidata
                                                                                      .itemHeaders
                                                                                      ? _c(
                                                                                          "br"
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mx-2",
                                                                                        class:
                                                                                          subitems.spanClass,
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            item[
                                                                                              subitems
                                                                                                .value
                                                                                            ] ===
                                                                                              1
                                                                                              ? _vm.$t(
                                                                                                  "message.customModule.completed"
                                                                                                )
                                                                                              : _vm.$t(
                                                                                                  "message.customModule.notCompleted"
                                                                                                )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                            ]
                                                                          : subitems.isTranslatable
                                                                          ? [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-2",
                                                                                  class:
                                                                                    subitems.spanClass,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          : _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-2",
                                                                                class:
                                                                                  subitems.spanClass,
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      item[
                                                                                        subitems
                                                                                          .value
                                                                                      ]
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ]
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              staticClass: "mx-0 mr-5",
                                              attrs: {
                                                row: "",
                                                wrap: "",
                                                "justify-end": "",
                                              },
                                            },
                                            [
                                              !_vm.eqidata.isChecklist &&
                                              _vm.eqidata.hasDelete &&
                                              _vm.checkActionPermission(
                                                _vm.eqidata.module,
                                                _vm.CONSTANTS.DELETE
                                              )
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        color: "error",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.deleteRecord(
                                                            item.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { large: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-delete-circle"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                  ],
                  null,
                  true
                ),
              }),
              _c(
                "p",
                { staticClass: "text-center px-5 py-0" },
                [
                  _vm.eqidata.loading
                    ? _c("v-progress-linear", {
                        attrs: {
                          color: "deep-purple accent-4",
                          indeterminate: "",
                          rounded: "",
                          height: "6",
                        },
                      })
                    : _vm._e(),
                  false
                    ? _c("v-progress-circular", {
                        staticClass: "mx-auto",
                        attrs: { indeterminate: "", color: "primary" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              !_vm.eqidata.loading && _vm.eqidata.list.length === 0
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-2",
                      attrs: { prominent: "", text: "", type: "info" },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { staticClass: "grow" }, [
                            _vm._v(_vm._s(_vm.$t("message.common.noResults"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.chemicalScrollList
        ? _c(
            "v-card-text",
            { staticClass: "pa-2 mt-2 mb-4" },
            [
              _c("h4", { staticClass: "headingdesign" }, [
                _vm._v(_vm._s(this.$t("message.layout.chemicallist"))),
              ]),
              (_vm.chemicaldata.itemHeaders &&
                _vm.chemicaldata.list &&
                _vm.chemicaldata.list.length &&
                _vm.$vuetify.breakpoint.name == "sm") ||
              _vm.$vuetify.breakpoint.name == "md" ||
              _vm.$vuetify.breakpoint.name == "lg" ||
              _vm.$vuetify.breakpoint.name == "xl"
                ? _c(
                    "v-card",
                    {
                      staticClass: "pa-0 mt-0 ",
                      attrs: { outlined: "", color: "#e8eef0" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _vm._l(
                            _vm.chemicaldata.itemHeaders,
                            function (header, index) {
                              return [
                                _c(
                                  "v-col",
                                  {
                                    key: index,
                                    class: _vm.chemicaldata.items[index].class,
                                    attrs: {
                                      cols: _vm.chemicaldata.items[index].cols,
                                    },
                                  },
                                  [
                                    header ===
                                      "message.projects.drawingGroup" &&
                                    _vm.chemicaldata.advanceDrawingTypes
                                      ? _c("strong", [
                                          _vm._v(_vm._s(_vm.$t(header))),
                                        ])
                                      : header !==
                                        "message.projects.drawingGroup"
                                      ? _c("strong", [
                                          _vm._v(_vm._s(_vm.$t(header))),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-virtual-scroll", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.chemicaldata.list.length > 0,
                    expression: "chemicaldata.list.length > 0",
                  },
                ],
                attrs: {
                  items: _vm.chemicaldata.list,
                  "item-height": _vm.getItemHeight("chemicalScrollList"),
                  title: _vm.chemicaldata.list.length,
                },
                scopedSlots: _vm._u(
                  [
                    _vm.$vuetify.breakpoint.name == "sm" ||
                    _vm.$vuetify.breakpoint.name == "md" ||
                    _vm.$vuetify.breakpoint.name == "lg" ||
                    _vm.$vuetify.breakpoint.name == "xl"
                      ? {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-1 mt-1",
                                  staticStyle: { overflow: "hidden" },
                                  attrs: { outlined: "", color: "#e8eaf6" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.chemicaldatalistRoute(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass: "pa-1",
                                      class:
                                        _vm.$t(_vm.chemicaldata.moduleTitle) ===
                                          _vm.$t("message.layout.drawing") &&
                                        item &&
                                        item.is_completed === 5
                                          ? "orange--text text--lighten-2"
                                          : "",
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          [
                                            _vm._l(
                                              _vm.chemicaldata.items,
                                              function (colValues, index) {
                                                return [
                                                  colValues.value
                                                    ? [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: index,
                                                            class:
                                                              colValues.class,
                                                            attrs: {
                                                              cols: colValues.cols,
                                                            },
                                                          },
                                                          [
                                                            colValues.isParagraph
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "custom-height",
                                                                    class:
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ] &&
                                                                      colValues.value ==
                                                                        "description" &&
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ].length >
                                                                        100
                                                                        ? "d-inline-block"
                                                                        : "",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item[
                                                                            colValues
                                                                              .value
                                                                          ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    class:
                                                                      colValues.spanClass,
                                                                  },
                                                                  [
                                                                    colValues.label
                                                                      ? _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                colValues.label
                                                                              ) +
                                                                                ": "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    colValues.label
                                                                      ? _c("br")
                                                                      : _vm._e(),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item[
                                                                          colValues
                                                                            .value
                                                                        ]
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        ),
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: index,
                                                            class:
                                                              colValues.class,
                                                            attrs: {
                                                              cols: colValues.cols,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                class:
                                                                  colValues.rowClass,
                                                              },
                                                              [
                                                                _vm._l(
                                                                  colValues.subItems,
                                                                  function (
                                                                    subitems,
                                                                    i
                                                                  ) {
                                                                    return [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          key: i,
                                                                          class:
                                                                            subitems.class,
                                                                          attrs:
                                                                            {
                                                                              cols: subitems.cols,
                                                                            },
                                                                        },
                                                                        [
                                                                          subitems.value ===
                                                                          "is_completed"
                                                                            ? [
                                                                                _vm
                                                                                  .chemicaldata
                                                                                  .moduleTitle ===
                                                                                "message.layout.drawing"
                                                                                  ? _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "px-0 pr-2",
                                                                                        attrs:
                                                                                          {
                                                                                            dense:
                                                                                              "",
                                                                                            "hide-details":
                                                                                              "",
                                                                                            "item-text":
                                                                                              "text",
                                                                                            "item-value":
                                                                                              "value",
                                                                                            items:
                                                                                              subitems.items,
                                                                                            label:
                                                                                              "Status",
                                                                                            outlined:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.stopPropagation()
                                                                                            },
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.saveStatusUpdate(
                                                                                                _vm.chemicaldata.list.indexOf(
                                                                                                  item
                                                                                                )
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              item[
                                                                                                subitems
                                                                                                  .value
                                                                                              ],
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  item,
                                                                                                  subitems.value,
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "item[subitems.value]",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : [
                                                                                      !_vm
                                                                                        .chemicaldata
                                                                                        .itemHeaders
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              class:
                                                                                                subitems.spanClass,
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "strong",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "message.projects.status"
                                                                                                      )
                                                                                                    ) +
                                                                                                      ":"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      !_vm
                                                                                        .chemicaldata
                                                                                        .itemHeaders
                                                                                        ? _c(
                                                                                            "br"
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          class:
                                                                                            subitems.spanClass,
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item[
                                                                                                subitems
                                                                                                  .value
                                                                                              ] ===
                                                                                                1
                                                                                                ? _vm.$t(
                                                                                                    "message.customModule.completed"
                                                                                                  )
                                                                                                : _vm.$t(
                                                                                                    "message.customModule.notCompleted"
                                                                                                  )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                              ]
                                                                            : subitems.isTranslatable
                                                                            ? [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      subitems.spanClass,
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          item[
                                                                                            subitems
                                                                                              .value
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            : _c(
                                                                                "span",
                                                                                {
                                                                                  class:
                                                                                    subitems.spanClass,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                ]
                                              }
                                            ),
                                          ],
                                          _vm.chemicaldata.actionSize
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0 pr-1 mt-3",
                                                  attrs: {
                                                    cols: _vm.chemicaldata
                                                      .actionSize,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      staticClass: "mx-0",
                                                      attrs: {
                                                        row: "",
                                                        wrap: "",
                                                        "justify-end": "",
                                                      },
                                                    },
                                                    [
                                                      !_vm.chemicaldata
                                                        .isChecklist &&
                                                      _vm.chemicaldata
                                                        .hasDelete &&
                                                      _vm.checkActionPermission(
                                                        _vm.chemicaldata.module,
                                                        _vm.CONSTANTS.DELETE
                                                      )
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                "max-height":
                                                                  "24",
                                                                "max-width":
                                                                  "24",
                                                                icon: "",
                                                                color: "error",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.deleteRecord(
                                                                      item.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-delete-circle"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        }
                      : {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-0 mt-2  overflow-x-hidden",
                                  attrs: { outlined: "", color: "#e8eaf6" },
                                  on: {
                                    click: function ($event) {
                                      _vm.checkActionPermission(
                                        _vm.chemicaldata.module,
                                        _vm.CONSTANTS.EDIT
                                      )
                                        ? _vm.scrollListPayload.editHandler(
                                            item
                                          )
                                        : ""
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0 px-4 my-2" },
                                    [
                                      _vm._l(
                                        _vm.chemicaldata.items,
                                        function (colValues, index) {
                                          return [
                                            _c(
                                              "v-row",
                                              {
                                                key: index,
                                                staticClass: "pt-1",
                                              },
                                              [
                                                _vm.chemicaldata.itemHeaders &&
                                                _vm.chemicaldata.itemHeaders
                                                  .length
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pa-0",
                                                        attrs: {
                                                          cols: !_vm
                                                            .chemicaldata
                                                            .hasDelete
                                                            ? "4"
                                                            : "3",
                                                        },
                                                      },
                                                      [
                                                        _vm.chemicaldata
                                                          .itemHeaders[
                                                          index
                                                        ] ===
                                                          "message.projects.drawingGroup" &&
                                                        _vm.chemicaldata
                                                          .advanceDrawingTypes
                                                          ? _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm
                                                                      .chemicaldata
                                                                      .itemHeaders[
                                                                      index
                                                                    ]
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm.chemicaldata
                                                              .itemHeaders[
                                                              index
                                                            ] !==
                                                            "message.projects.drawingGroup"
                                                          ? _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm
                                                                      .chemicaldata
                                                                      .itemHeaders[
                                                                      index
                                                                    ]
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-0",
                                                    class:
                                                      _vm.$t(
                                                        _vm.chemicaldata
                                                          .moduleTitle
                                                      ) ===
                                                        _vm.$t(
                                                          "message.layout.drawing"
                                                        ) &&
                                                      item &&
                                                      item.is_completed === 5
                                                        ? "orange--text text--lighten-2"
                                                        : "",
                                                    attrs: {
                                                      cols: !_vm.chemicaldata
                                                        .hasDelete
                                                        ? "8"
                                                        : "8",
                                                    },
                                                  },
                                                  [
                                                    colValues.value
                                                      ? [
                                                          colValues.isParagraph
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item[
                                                                          colValues
                                                                            .value
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                  class:
                                                                    colValues.spanClass,
                                                                },
                                                                [
                                                                  colValues.label
                                                                    ? _c(
                                                                        "strong",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              colValues.label
                                                                            ) +
                                                                              ": "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  colValues.label
                                                                    ? _c("br")
                                                                    : _vm._e(),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ]
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      : [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              class:
                                                                colValues.rowClass,
                                                            },
                                                            [
                                                              _vm._l(
                                                                colValues.subItems,
                                                                function (
                                                                  subitems,
                                                                  i
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        key: i,
                                                                        class:
                                                                          subitems.class,
                                                                        attrs: {
                                                                          cols: subitems.cols,
                                                                        },
                                                                      },
                                                                      [
                                                                        subitems.value ===
                                                                        "is_completed"
                                                                          ? [
                                                                              _vm
                                                                                .chemicaldata
                                                                                .moduleTitle ===
                                                                              "message.layout.drawing"
                                                                                ? _c(
                                                                                    "v-select",
                                                                                    {
                                                                                      staticClass:
                                                                                        "px-0 pr-2",
                                                                                      attrs:
                                                                                        {
                                                                                          dense:
                                                                                            "",
                                                                                          "hide-details":
                                                                                            "",
                                                                                          "item-text":
                                                                                            "text",
                                                                                          "item-value":
                                                                                            "value",
                                                                                          items:
                                                                                            subitems.items,
                                                                                          label:
                                                                                            "Status",
                                                                                          outlined:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                          },
                                                                                        change:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.saveStatusUpdate(
                                                                                              _vm.chemicaldata.list.indexOf(
                                                                                                item
                                                                                              )
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            item[
                                                                                              subitems
                                                                                                .value
                                                                                            ],
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                item,
                                                                                                subitems.value,
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "item[subitems.value]",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : [
                                                                                    !_vm
                                                                                      .chemicaldata
                                                                                      .itemHeaders
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "mx-2",
                                                                                            class:
                                                                                              subitems.spanClass,
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "strong",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "message.projects.status"
                                                                                                    )
                                                                                                  ) +
                                                                                                    ":"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    !_vm
                                                                                      .chemicaldata
                                                                                      .itemHeaders
                                                                                      ? _c(
                                                                                          "br"
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mx-2",
                                                                                        class:
                                                                                          subitems.spanClass,
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            item[
                                                                                              subitems
                                                                                                .value
                                                                                            ] ===
                                                                                              1
                                                                                              ? _vm.$t(
                                                                                                  "message.customModule.completed"
                                                                                                )
                                                                                              : _vm.$t(
                                                                                                  "message.customModule.notCompleted"
                                                                                                )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                            ]
                                                                          : subitems.isTranslatable
                                                                          ? [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-2",
                                                                                  class:
                                                                                    subitems.spanClass,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          : _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-2",
                                                                                class:
                                                                                  subitems.spanClass,
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      item[
                                                                                        subitems
                                                                                          .value
                                                                                      ]
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ]
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              staticClass: "mx-0 mr-5",
                                              attrs: {
                                                row: "",
                                                wrap: "",
                                                "justify-end": "",
                                              },
                                            },
                                            [
                                              !_vm.chemicaldata.isChecklist &&
                                              _vm.chemicaldata.hasDelete &&
                                              _vm.checkActionPermission(
                                                _vm.chemicaldata.module,
                                                _vm.CONSTANTS.DELETE
                                              )
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        color: "error",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.deleteRecord(
                                                            item.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { large: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-delete-circle"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                  ],
                  null,
                  true
                ),
              }),
              _c(
                "p",
                { staticClass: "text-center px-5 py-0" },
                [
                  _vm.chemicaldata.loading
                    ? _c("v-progress-linear", {
                        attrs: {
                          color: "deep-purple accent-4",
                          indeterminate: "",
                          rounded: "",
                          height: "6",
                        },
                      })
                    : _vm._e(),
                  false
                    ? _c("v-progress-circular", {
                        staticClass: "mx-auto",
                        attrs: { indeterminate: "", color: "primary" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              !_vm.chemicaldata.loading && _vm.chemicaldata.list.length === 0
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-2",
                      attrs: { prominent: "", text: "", type: "info" },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { staticClass: "grow" }, [
                            _vm._v(_vm._s(_vm.$t("message.common.noResults"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.projectdataScrollList
        ? _c(
            "v-card-text",
            { staticClass: "pa-2 mt-2 mb-4" },
            [
              _c("h4", { staticClass: "headingdesign" }, [
                _vm._v(
                  _vm._s(
                    this.hedingofproject === "Event"
                      ? this.$t("message.layout.event")
                      : this.hedingofproject === "PWHT"
                      ? this.$t("message.layout.pwht")
                      : this.hedingofproject === "Drawing"
                      ? this.$t("message.layout.drawing")
                      : this.hedingofproject === "Matrial List"
                      ? this.$t("message.layout.matrialList")
                      : this.hedingofproject === "Weld Log"
                      ? this.$t("message.layout.weldlog")
                      : this.hedingofproject === "NDT"
                      ? this.$t("message.layout.ndt")
                      : this.hedingofproject === "AS-Built"
                      ? this.$t("message.layout.asbuilt")
                      : this.hedingofproject === "Technical Report"
                      ? this.$t("message.layout.technicalReport")
                      : this.hedingofproject === "Surface Report"
                      ? this.$t("message.layout.surfaceReport")
                      : this.hedingofproject === "Pressure Test Report"
                      ? this.$t("message.layout.pressureTestReport")
                      : this.hedingofproject === "PWPS"
                      ? this.$t("message.layout.pwps")
                      : this.hedingofproject
                  ) + " "
                ),
              ]),
              (_vm.projectdata.itemHeaders &&
                _vm.projectdata.list &&
                _vm.projectdata.list.length &&
                _vm.$vuetify.breakpoint.name == "sm") ||
              _vm.$vuetify.breakpoint.name == "md" ||
              _vm.$vuetify.breakpoint.name == "lg" ||
              _vm.$vuetify.breakpoint.name == "xl"
                ? _c(
                    "v-card",
                    {
                      staticClass: "pa-0 mt-0",
                      attrs: { outlined: "", color: "#e8eef0" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _vm._l(
                            _vm.projectdata.itemHeaders,
                            function (header, index) {
                              return [
                                _c(
                                  "v-col",
                                  {
                                    key: index,
                                    class: _vm.projectdata.items[index].class,
                                    attrs: {
                                      cols: _vm.projectdata.items[index].cols,
                                    },
                                  },
                                  [
                                    header ===
                                      "message.projects.drawingGroup" &&
                                    _vm.projectdata.advanceDrawingTypes
                                      ? _c("strong", [
                                          _vm._v(_vm._s(_vm.$t(header))),
                                        ])
                                      : header !==
                                        "message.projects.drawingGroup"
                                      ? _c("strong", [
                                          _vm._v(_vm._s(_vm.$t(header))),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-virtual-scroll", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.projectdata.list.length > 0,
                    expression: "projectdata.list.length > 0",
                  },
                ],
                attrs: {
                  items: _vm.projectdata.list,
                  "item-height": _vm.getItemHeight("projectdataScrollList"),
                  title: _vm.projectdata.list.length,
                },
                scopedSlots: _vm._u(
                  [
                    _vm.$vuetify.breakpoint.name == "sm" ||
                    _vm.$vuetify.breakpoint.name == "md" ||
                    _vm.$vuetify.breakpoint.name == "lg" ||
                    _vm.$vuetify.breakpoint.name == "xl"
                      ? {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-1 mt-1",
                                  staticStyle: { overflow: "hidden" },
                                  attrs: { outlined: "", color: "#e8eaf6" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.projectlistdataRoute(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass: "pa-1",
                                      class:
                                        _vm.$t(_vm.projectdata.moduleTitle) ===
                                          _vm.$t("message.layout.drawing") &&
                                        item &&
                                        item.is_completed === 5
                                          ? "orange--text text--lighten-2"
                                          : "",
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          [
                                            _vm._l(
                                              _vm.projectdata.items,
                                              function (colValues, index) {
                                                return [
                                                  colValues.value
                                                    ? [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: index,
                                                            class:
                                                              colValues.class,
                                                            attrs: {
                                                              cols: colValues.cols,
                                                            },
                                                          },
                                                          [
                                                            colValues.isParagraph
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "custom-height",
                                                                    class:
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ] &&
                                                                      colValues.value ==
                                                                        "description" &&
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ].length >
                                                                        100
                                                                        ? "d-inline-block"
                                                                        : "",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item[
                                                                            colValues
                                                                              .value
                                                                          ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    class:
                                                                      colValues.spanClass,
                                                                  },
                                                                  [
                                                                    colValues.label
                                                                      ? _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                colValues.label
                                                                              ) +
                                                                                ": "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    colValues.label
                                                                      ? _c("br")
                                                                      : _vm._e(),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item[
                                                                          colValues
                                                                            .value
                                                                        ]
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        ),
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: index,
                                                            class:
                                                              colValues.class,
                                                            attrs: {
                                                              cols: colValues.cols,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                class:
                                                                  colValues.rowClass,
                                                              },
                                                              [
                                                                _vm._l(
                                                                  colValues.subItems,
                                                                  function (
                                                                    subitems,
                                                                    i
                                                                  ) {
                                                                    return [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          key: i,
                                                                          class:
                                                                            subitems.class,
                                                                          attrs:
                                                                            {
                                                                              cols: subitems.cols,
                                                                            },
                                                                        },
                                                                        [
                                                                          subitems.value ===
                                                                          "is_completed"
                                                                            ? [
                                                                                _vm
                                                                                  .projectdata
                                                                                  .moduleTitle ===
                                                                                "message.layout.drawing"
                                                                                  ? _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "px-0 pr-2",
                                                                                        attrs:
                                                                                          {
                                                                                            dense:
                                                                                              "",
                                                                                            "hide-details":
                                                                                              "",
                                                                                            "item-text":
                                                                                              "text",
                                                                                            "item-value":
                                                                                              "value",
                                                                                            items:
                                                                                              subitems.items,
                                                                                            label:
                                                                                              "Status",
                                                                                            outlined:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.stopPropagation()
                                                                                            },
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.saveStatusUpdate(
                                                                                                _vm.projectdata.list.indexOf(
                                                                                                  item
                                                                                                )
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              item[
                                                                                                subitems
                                                                                                  .value
                                                                                              ],
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  item,
                                                                                                  subitems.value,
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "item[subitems.value]",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : [
                                                                                      !_vm
                                                                                        .projectdata
                                                                                        .itemHeaders
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              class:
                                                                                                subitems.spanClass,
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "strong",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "message.projects.status"
                                                                                                      )
                                                                                                    ) +
                                                                                                      ":"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      !_vm
                                                                                        .projectdata
                                                                                        .itemHeaders
                                                                                        ? _c(
                                                                                            "br"
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          class:
                                                                                            subitems.spanClass,
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item[
                                                                                                subitems
                                                                                                  .value
                                                                                              ] ===
                                                                                                1
                                                                                                ? _vm.$t(
                                                                                                    "message.customModule.completed"
                                                                                                  )
                                                                                                : _vm.$t(
                                                                                                    "message.customModule.notCompleted"
                                                                                                  )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                              ]
                                                                            : subitems.isTranslatable
                                                                            ? [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      subitems.spanClass,
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          item[
                                                                                            subitems
                                                                                              .value
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            : _c(
                                                                                "span",
                                                                                {
                                                                                  class:
                                                                                    subitems.spanClass,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                ]
                                              }
                                            ),
                                          ],
                                          _vm.projectdata.actionSize
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0 pr-1 mt-3",
                                                  attrs: {
                                                    cols: _vm.projectdata
                                                      .actionSize,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      staticClass: "mx-0",
                                                      attrs: {
                                                        row: "",
                                                        wrap: "",
                                                        "justify-end": "",
                                                      },
                                                    },
                                                    [
                                                      !_vm.projectdata
                                                        .isChecklist &&
                                                      _vm.projectdata
                                                        .hasDelete &&
                                                      _vm.checkActionPermission(
                                                        _vm.projectdata.module,
                                                        _vm.CONSTANTS.DELETE
                                                      )
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                "max-height":
                                                                  "24",
                                                                "max-width":
                                                                  "24",
                                                                icon: "",
                                                                color: "error",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.deleteRecord(
                                                                      item.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-delete-circle"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        }
                      : {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-0 mt-2  overflow-x-hidden",
                                  attrs: { outlined: "", color: "#e8eaf6" },
                                  on: {
                                    click: function ($event) {
                                      _vm.checkActionPermission(
                                        _vm.projectdata.module,
                                        _vm.CONSTANTS.EDIT
                                      )
                                        ? _vm.scrollListPayload.editHandler(
                                            item
                                          )
                                        : ""
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0 px-4 my-2" },
                                    [
                                      _vm._l(
                                        _vm.projectdata.items,
                                        function (colValues, index) {
                                          return [
                                            _c(
                                              "v-row",
                                              {
                                                key: index,
                                                staticClass: "pt-1",
                                              },
                                              [
                                                _vm.projectdata.itemHeaders &&
                                                _vm.projectdata.itemHeaders
                                                  .length
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pa-0",
                                                        attrs: {
                                                          cols: !_vm.projectdata
                                                            .hasDelete
                                                            ? "4"
                                                            : "3",
                                                        },
                                                      },
                                                      [
                                                        _vm.projectdata
                                                          .itemHeaders[
                                                          index
                                                        ] ===
                                                          "message.projects.drawingGroup" &&
                                                        _vm.projectdata
                                                          .advanceDrawingTypes
                                                          ? _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm
                                                                      .projectdata
                                                                      .itemHeaders[
                                                                      index
                                                                    ]
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm.projectdata
                                                              .itemHeaders[
                                                              index
                                                            ] !==
                                                            "message.projects.drawingGroup"
                                                          ? _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm
                                                                      .projectdata
                                                                      .itemHeaders[
                                                                      index
                                                                    ]
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-0",
                                                    class:
                                                      _vm.$t(
                                                        _vm.projectdata
                                                          .moduleTitle
                                                      ) ===
                                                        _vm.$t(
                                                          "message.layout.drawing"
                                                        ) &&
                                                      item &&
                                                      item.is_completed === 5
                                                        ? "orange--text text--lighten-2"
                                                        : "",
                                                    attrs: {
                                                      cols: !_vm.projectdata
                                                        .hasDelete
                                                        ? "8"
                                                        : "8",
                                                    },
                                                  },
                                                  [
                                                    colValues.value
                                                      ? [
                                                          colValues.isParagraph
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item[
                                                                          colValues
                                                                            .value
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                  class:
                                                                    colValues.spanClass,
                                                                },
                                                                [
                                                                  colValues.label
                                                                    ? _c(
                                                                        "strong",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              colValues.label
                                                                            ) +
                                                                              ": "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  colValues.label
                                                                    ? _c("br")
                                                                    : _vm._e(),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ]
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      : [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              class:
                                                                colValues.rowClass,
                                                            },
                                                            [
                                                              _vm._l(
                                                                colValues.subItems,
                                                                function (
                                                                  subitems,
                                                                  i
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        key: i,
                                                                        class:
                                                                          subitems.class,
                                                                        attrs: {
                                                                          cols: subitems.cols,
                                                                        },
                                                                      },
                                                                      [
                                                                        subitems.value ===
                                                                        "is_completed"
                                                                          ? [
                                                                              _vm
                                                                                .projectdata
                                                                                .moduleTitle ===
                                                                              "message.layout.drawing"
                                                                                ? _c(
                                                                                    "v-select",
                                                                                    {
                                                                                      staticClass:
                                                                                        "px-0 pr-2",
                                                                                      attrs:
                                                                                        {
                                                                                          dense:
                                                                                            "",
                                                                                          "hide-details":
                                                                                            "",
                                                                                          "item-text":
                                                                                            "text",
                                                                                          "item-value":
                                                                                            "value",
                                                                                          items:
                                                                                            subitems.items,
                                                                                          label:
                                                                                            "Status",
                                                                                          outlined:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                          },
                                                                                        change:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.saveStatusUpdate(
                                                                                              _vm.projectdata.list.indexOf(
                                                                                                item
                                                                                              )
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            item[
                                                                                              subitems
                                                                                                .value
                                                                                            ],
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                item,
                                                                                                subitems.value,
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "item[subitems.value]",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : [
                                                                                    !_vm
                                                                                      .projectdata
                                                                                      .itemHeaders
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "mx-2",
                                                                                            class:
                                                                                              subitems.spanClass,
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "strong",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "message.projects.status"
                                                                                                    )
                                                                                                  ) +
                                                                                                    ":"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    !_vm
                                                                                      .projectdata
                                                                                      .itemHeaders
                                                                                      ? _c(
                                                                                          "br"
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mx-2",
                                                                                        class:
                                                                                          subitems.spanClass,
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            item[
                                                                                              subitems
                                                                                                .value
                                                                                            ] ===
                                                                                              1
                                                                                              ? _vm.$t(
                                                                                                  "message.customModule.completed"
                                                                                                )
                                                                                              : _vm.$t(
                                                                                                  "message.customModule.notCompleted"
                                                                                                )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                            ]
                                                                          : subitems.isTranslatable
                                                                          ? [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-2",
                                                                                  class:
                                                                                    subitems.spanClass,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          : _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-2",
                                                                                class:
                                                                                  subitems.spanClass,
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      item[
                                                                                        subitems
                                                                                          .value
                                                                                      ]
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ]
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              staticClass: "mx-0 mr-5",
                                              attrs: {
                                                row: "",
                                                wrap: "",
                                                "justify-end": "",
                                              },
                                            },
                                            [
                                              !_vm.projectdata.isChecklist &&
                                              _vm.projectdata.hasDelete &&
                                              _vm.checkActionPermission(
                                                _vm.projectdata.module,
                                                _vm.CONSTANTS.DELETE
                                              )
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        color: "error",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.deleteRecord(
                                                            item.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { large: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-delete-circle"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                  ],
                  null,
                  true
                ),
              }),
              _c(
                "p",
                { staticClass: "text-center px-5 py-0" },
                [
                  _vm.projectdata.loading
                    ? _c("v-progress-linear", {
                        attrs: {
                          color: "deep-purple accent-4",
                          indeterminate: "",
                          rounded: "",
                          height: "6",
                        },
                      })
                    : _vm._e(),
                  false
                    ? _c("v-progress-circular", {
                        staticClass: "mx-auto",
                        attrs: { indeterminate: "", color: "primary" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              !_vm.projectdata.loading && _vm.projectdata.list.length === 0
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-2",
                      attrs: { prominent: "", text: "", type: "info" },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { staticClass: "grow" }, [
                            _vm._v(_vm._s(_vm.$t("message.common.noResults"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.ordersdataScrollList
        ? _c(
            "v-card-text",
            { staticClass: "pa-2 mt-2 mb-4" },
            [
              _c("h4", { staticClass: "headingdesign" }, [
                _vm._v(
                  _vm._s(
                    _vm.headingTitle.ordersdata === 3
                      ? this.$t("message.whsDashboard.supplierOrder")
                      : _vm.headingTitle.ordersdata === 4
                      ? this.$t("message.whsDashboard.returnOrder")
                      : _vm.headingTitle.ordersdata === 2
                      ? this.$t("message.whsDashboard.adjustmentOrders")
                      : _vm.headingTitle.ordersdata === 1
                      ? this.$t("message.whsDashboard.transferOrders")
                      : ""
                  ) + " "
                ),
              ]),
              (_vm.ordersdata.itemHeaders &&
                _vm.ordersdata.list &&
                _vm.ordersdata.list.length &&
                _vm.$vuetify.breakpoint.name == "sm") ||
              _vm.$vuetify.breakpoint.name == "md" ||
              _vm.$vuetify.breakpoint.name == "lg" ||
              _vm.$vuetify.breakpoint.name == "xl"
                ? _c(
                    "v-card",
                    {
                      staticClass: "pa-0 mt-0 ",
                      attrs: { outlined: "", color: "#e8eef0" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _vm._l(
                            _vm.ordersdata.itemHeaders,
                            function (header, index) {
                              return [
                                _c(
                                  "v-col",
                                  {
                                    key: index,
                                    class: _vm.ordersdata.items[index].class,
                                    attrs: {
                                      cols: _vm.ordersdata.items[index].cols,
                                    },
                                  },
                                  [
                                    header ===
                                      "message.projects.drawingGroup" &&
                                    _vm.ordersdata.advanceDrawingTypes
                                      ? _c("strong", [
                                          _vm._v(_vm._s(_vm.$t(header))),
                                        ])
                                      : header !==
                                        "message.projects.drawingGroup"
                                      ? _c("strong", [
                                          _vm._v(_vm._s(_vm.$t(header))),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-virtual-scroll", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.ordersdata.list.length > 0,
                    expression: "ordersdata.list.length > 0",
                  },
                ],
                attrs: {
                  items: _vm.ordersdata.list,
                  "item-height": _vm.getItemHeight("ordersdataScrollList"),
                  title: _vm.ordersdata.list.length,
                },
                scopedSlots: _vm._u(
                  [
                    _vm.$vuetify.breakpoint.name == "sm" ||
                    _vm.$vuetify.breakpoint.name == "md" ||
                    _vm.$vuetify.breakpoint.name == "lg" ||
                    _vm.$vuetify.breakpoint.name == "xl"
                      ? {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-1 mt-1",
                                  staticStyle: { overflow: "hidden" },
                                  attrs: { outlined: "", color: "#e8eaf6" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.ordersdatalistRoute(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass: "pa-1",
                                      class:
                                        _vm.$t(_vm.ordersdata.moduleTitle) ===
                                          _vm.$t("message.layout.drawing") &&
                                        item &&
                                        item.is_completed === 5
                                          ? "orange--text text--lighten-2"
                                          : "",
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          [
                                            _vm._l(
                                              _vm.ordersdata.items,
                                              function (colValues, index) {
                                                return [
                                                  colValues.value
                                                    ? [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: index,
                                                            class:
                                                              colValues.class,
                                                            attrs: {
                                                              cols: colValues.cols,
                                                            },
                                                          },
                                                          [
                                                            colValues.isParagraph
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "custom-height",
                                                                    class:
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ] &&
                                                                      colValues.value ==
                                                                        "description" &&
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ].length >
                                                                        100
                                                                        ? "d-inline-block"
                                                                        : "",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item[
                                                                            colValues
                                                                              .value
                                                                          ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    class:
                                                                      colValues.spanClass,
                                                                  },
                                                                  [
                                                                    colValues.label
                                                                      ? _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                colValues.label
                                                                              ) +
                                                                                ": "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    colValues.label
                                                                      ? _c("br")
                                                                      : _vm._e(),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item[
                                                                          colValues
                                                                            .value
                                                                        ]
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        ),
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: index,
                                                            class:
                                                              colValues.class,
                                                            attrs: {
                                                              cols: colValues.cols,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                class:
                                                                  colValues.rowClass,
                                                              },
                                                              [
                                                                _vm._l(
                                                                  colValues.subItems,
                                                                  function (
                                                                    subitems,
                                                                    i
                                                                  ) {
                                                                    return [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          key: i,
                                                                          class:
                                                                            subitems.class,
                                                                          attrs:
                                                                            {
                                                                              cols: subitems.cols,
                                                                            },
                                                                        },
                                                                        [
                                                                          subitems.value ===
                                                                          "is_completed"
                                                                            ? [
                                                                                _vm
                                                                                  .ordersdata
                                                                                  .moduleTitle ===
                                                                                "message.layout.drawing"
                                                                                  ? _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "px-0 pr-2",
                                                                                        attrs:
                                                                                          {
                                                                                            dense:
                                                                                              "",
                                                                                            "hide-details":
                                                                                              "",
                                                                                            "item-text":
                                                                                              "text",
                                                                                            "item-value":
                                                                                              "value",
                                                                                            items:
                                                                                              subitems.items,
                                                                                            label:
                                                                                              "Status",
                                                                                            outlined:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.stopPropagation()
                                                                                            },
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.saveStatusUpdate(
                                                                                                _vm.ordersdata.list.indexOf(
                                                                                                  item
                                                                                                )
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              item[
                                                                                                subitems
                                                                                                  .value
                                                                                              ],
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  item,
                                                                                                  subitems.value,
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "item[subitems.value]",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : [
                                                                                      !_vm
                                                                                        .ordersdata
                                                                                        .itemHeaders
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              class:
                                                                                                subitems.spanClass,
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "strong",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "message.projects.status"
                                                                                                      )
                                                                                                    ) +
                                                                                                      ":"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      !_vm
                                                                                        .ordersdata
                                                                                        .itemHeaders
                                                                                        ? _c(
                                                                                            "br"
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          class:
                                                                                            subitems.spanClass,
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item[
                                                                                                subitems
                                                                                                  .value
                                                                                              ] ===
                                                                                                1
                                                                                                ? _vm.$t(
                                                                                                    "message.customModule.completed"
                                                                                                  )
                                                                                                : _vm.$t(
                                                                                                    "message.customModule.notCompleted"
                                                                                                  )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                              ]
                                                                            : subitems.isTranslatable
                                                                            ? [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      subitems.spanClass,
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          item[
                                                                                            subitems
                                                                                              .value
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            : _c(
                                                                                "span",
                                                                                {
                                                                                  class:
                                                                                    subitems.spanClass,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                ]
                                              }
                                            ),
                                          ],
                                          _vm.ordersdata.actionSize
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0 pr-1 mt-3",
                                                  attrs: {
                                                    cols: _vm.ordersdata
                                                      .actionSize,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      staticClass: "mx-0",
                                                      attrs: {
                                                        row: "",
                                                        wrap: "",
                                                        "justify-end": "",
                                                      },
                                                    },
                                                    [
                                                      !_vm.ordersdata
                                                        .isChecklist &&
                                                      _vm.ordersdata
                                                        .hasDelete &&
                                                      _vm.checkActionPermission(
                                                        _vm.ordersdata.module,
                                                        _vm.CONSTANTS.DELETE
                                                      )
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                "max-height":
                                                                  "24",
                                                                "max-width":
                                                                  "24",
                                                                icon: "",
                                                                color: "error",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.deleteRecord(
                                                                      item.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-delete-circle"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        }
                      : {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-0 mt-2  overflow-x-hidden",
                                  attrs: { outlined: "", color: "#e8eaf6" },
                                  on: {
                                    click: function ($event) {
                                      _vm.checkActionPermission(
                                        _vm.ordersdata.module,
                                        _vm.CONSTANTS.EDIT
                                      )
                                        ? _vm.scrollListPayload.editHandler(
                                            item
                                          )
                                        : ""
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0 px-4 my-2" },
                                    [
                                      _vm._l(
                                        _vm.ordersdata.items,
                                        function (colValues, index) {
                                          return [
                                            _c(
                                              "v-row",
                                              {
                                                key: index,
                                                staticClass: "pt-1",
                                              },
                                              [
                                                _vm.ordersdata.itemHeaders &&
                                                _vm.ordersdata.itemHeaders
                                                  .length
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pa-0",
                                                        attrs: {
                                                          cols: !_vm.ordersdata
                                                            .hasDelete
                                                            ? "4"
                                                            : "3",
                                                        },
                                                      },
                                                      [
                                                        _vm.ordersdata
                                                          .itemHeaders[
                                                          index
                                                        ] ===
                                                          "message.projects.drawingGroup" &&
                                                        _vm.ordersdata
                                                          .advanceDrawingTypes
                                                          ? _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm
                                                                      .ordersdata
                                                                      .itemHeaders[
                                                                      index
                                                                    ]
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm.ordersdata
                                                              .itemHeaders[
                                                              index
                                                            ] !==
                                                            "message.projects.drawingGroup"
                                                          ? _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm
                                                                      .ordersdata
                                                                      .itemHeaders[
                                                                      index
                                                                    ]
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-0",
                                                    class:
                                                      _vm.$t(
                                                        _vm.ordersdata
                                                          .moduleTitle
                                                      ) ===
                                                        _vm.$t(
                                                          "message.layout.drawing"
                                                        ) &&
                                                      item &&
                                                      item.is_completed === 5
                                                        ? "orange--text text--lighten-2"
                                                        : "",
                                                    attrs: {
                                                      cols: !_vm.ordersdata
                                                        .hasDelete
                                                        ? "8"
                                                        : "8",
                                                    },
                                                  },
                                                  [
                                                    colValues.value
                                                      ? [
                                                          colValues.isParagraph
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item[
                                                                          colValues
                                                                            .value
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                  class:
                                                                    colValues.spanClass,
                                                                },
                                                                [
                                                                  colValues.label
                                                                    ? _c(
                                                                        "strong",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              colValues.label
                                                                            ) +
                                                                              ": "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  colValues.label
                                                                    ? _c("br")
                                                                    : _vm._e(),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ]
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      : [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              class:
                                                                colValues.rowClass,
                                                            },
                                                            [
                                                              _vm._l(
                                                                colValues.subItems,
                                                                function (
                                                                  subitems,
                                                                  i
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        key: i,
                                                                        class:
                                                                          subitems.class,
                                                                        attrs: {
                                                                          cols: subitems.cols,
                                                                        },
                                                                      },
                                                                      [
                                                                        subitems.value ===
                                                                        "is_completed"
                                                                          ? [
                                                                              _vm
                                                                                .ordersdata
                                                                                .moduleTitle ===
                                                                              "message.layout.drawing"
                                                                                ? _c(
                                                                                    "v-select",
                                                                                    {
                                                                                      staticClass:
                                                                                        "px-0 pr-2",
                                                                                      attrs:
                                                                                        {
                                                                                          dense:
                                                                                            "",
                                                                                          "hide-details":
                                                                                            "",
                                                                                          "item-text":
                                                                                            "text",
                                                                                          "item-value":
                                                                                            "value",
                                                                                          items:
                                                                                            subitems.items,
                                                                                          label:
                                                                                            "Status",
                                                                                          outlined:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                          },
                                                                                        change:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.saveStatusUpdate(
                                                                                              _vm.ordersdata.list.indexOf(
                                                                                                item
                                                                                              )
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            item[
                                                                                              subitems
                                                                                                .value
                                                                                            ],
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                item,
                                                                                                subitems.value,
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "item[subitems.value]",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : [
                                                                                    !_vm
                                                                                      .ordersdata
                                                                                      .itemHeaders
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "mx-2",
                                                                                            class:
                                                                                              subitems.spanClass,
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "strong",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "message.projects.status"
                                                                                                    )
                                                                                                  ) +
                                                                                                    ":"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    !_vm
                                                                                      .ordersdata
                                                                                      .itemHeaders
                                                                                      ? _c(
                                                                                          "br"
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mx-2",
                                                                                        class:
                                                                                          subitems.spanClass,
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            item[
                                                                                              subitems
                                                                                                .value
                                                                                            ] ===
                                                                                              1
                                                                                              ? _vm.$t(
                                                                                                  "message.customModule.completed"
                                                                                                )
                                                                                              : _vm.$t(
                                                                                                  "message.customModule.notCompleted"
                                                                                                )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                            ]
                                                                          : subitems.isTranslatable
                                                                          ? [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-2",
                                                                                  class:
                                                                                    subitems.spanClass,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          : _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-2",
                                                                                class:
                                                                                  subitems.spanClass,
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      item[
                                                                                        subitems
                                                                                          .value
                                                                                      ]
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ]
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              staticClass: "mx-0 mr-5",
                                              attrs: {
                                                row: "",
                                                wrap: "",
                                                "justify-end": "",
                                              },
                                            },
                                            [
                                              !_vm.ordersdata.isChecklist &&
                                              _vm.ordersdata.hasDelete &&
                                              _vm.checkActionPermission(
                                                _vm.ordersdata.module,
                                                _vm.CONSTANTS.DELETE
                                              )
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        color: "error",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.deleteRecord(
                                                            item.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { large: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-delete-circle"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                  ],
                  null,
                  true
                ),
              }),
              _c(
                "p",
                { staticClass: "text-center px-5 py-0" },
                [
                  _vm.ordersdata.loading
                    ? _c("v-progress-linear", {
                        attrs: {
                          color: "deep-purple accent-4",
                          indeterminate: "",
                          rounded: "",
                          height: "6",
                        },
                      })
                    : _vm._e(),
                  false
                    ? _c("v-progress-circular", {
                        staticClass: "mx-auto",
                        attrs: { indeterminate: "", color: "primary" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              !_vm.ordersdata.loading && _vm.ordersdata.list.length === 0
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-2",
                      attrs: { prominent: "", text: "", type: "info" },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { staticClass: "grow" }, [
                            _vm._v(_vm._s(_vm.$t("message.common.noResults"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.productdataScrollList
        ? _c(
            "v-card-text",
            { staticClass: "pa-2 mt-2 mb-4" },
            [
              _c("h4", { staticClass: "headingdesign" }, [
                _vm._v(_vm._s(this.$t("message.order.products"))),
              ]),
              (_vm.productdata.itemHeaders &&
                _vm.productdata.list &&
                _vm.productdata.list.length &&
                _vm.$vuetify.breakpoint.name == "sm") ||
              _vm.$vuetify.breakpoint.name == "md" ||
              _vm.$vuetify.breakpoint.name == "lg" ||
              _vm.$vuetify.breakpoint.name == "xl"
                ? _c(
                    "v-card",
                    {
                      staticClass: "pa-0 mt-0",
                      attrs: { outlined: "", color: "#e8eef0" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _vm._l(
                            _vm.productdata.itemHeaders,
                            function (header, index) {
                              return [
                                _c(
                                  "v-col",
                                  {
                                    key: index,
                                    class: _vm.productdata.items[index].class,
                                    attrs: {
                                      cols: _vm.productdata.items[index].cols,
                                    },
                                  },
                                  [
                                    header ===
                                      "message.projects.drawingGroup" &&
                                    _vm.productdata.advanceDrawingTypes
                                      ? _c("strong", [
                                          _vm._v(_vm._s(_vm.$t(header))),
                                        ])
                                      : header !==
                                        "message.projects.drawingGroup"
                                      ? _c("strong", [
                                          _vm._v(_vm._s(_vm.$t(header))),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-virtual-scroll", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.productdata.list.length > 0,
                    expression: "productdata.list.length > 0",
                  },
                ],
                attrs: {
                  items: _vm.productdata.list,
                  "item-height": _vm.getItemHeight("productdataScrollList"),
                  title: _vm.productdata.list.length,
                },
                scopedSlots: _vm._u(
                  [
                    _vm.$vuetify.breakpoint.name == "sm" ||
                    _vm.$vuetify.breakpoint.name == "md" ||
                    _vm.$vuetify.breakpoint.name == "lg" ||
                    _vm.$vuetify.breakpoint.name == "xl"
                      ? {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-1 mt-1",
                                  staticStyle: { overflow: "hidden" },
                                  attrs: { outlined: "", color: "#e8eaf6" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.productdatalistRoute(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass: "pa-1",
                                      class:
                                        _vm.$t(_vm.productdata.moduleTitle) ===
                                          _vm.$t("message.layout.drawing") &&
                                        item &&
                                        item.is_completed === 5
                                          ? "orange--text text--lighten-2"
                                          : "",
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          [
                                            _vm._l(
                                              _vm.productdata.items,
                                              function (colValues, index) {
                                                return [
                                                  colValues.value
                                                    ? [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: index,
                                                            class:
                                                              colValues.class,
                                                            attrs: {
                                                              cols: colValues.cols,
                                                            },
                                                          },
                                                          [
                                                            colValues.isParagraph
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "custom-height",
                                                                    class:
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ] &&
                                                                      colValues.value ==
                                                                        "description" &&
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ].length >
                                                                        100
                                                                        ? "d-inline-block"
                                                                        : "",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item[
                                                                            colValues
                                                                              .value
                                                                          ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    class:
                                                                      colValues.spanClass,
                                                                  },
                                                                  [
                                                                    colValues.label
                                                                      ? _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                colValues.label
                                                                              ) +
                                                                                ": "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    colValues.label
                                                                      ? _c("br")
                                                                      : _vm._e(),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item[
                                                                          colValues
                                                                            .value
                                                                        ]
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        ),
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: index,
                                                            class:
                                                              colValues.class,
                                                            attrs: {
                                                              cols: colValues.cols,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                class:
                                                                  colValues.rowClass,
                                                              },
                                                              [
                                                                _vm._l(
                                                                  colValues.subItems,
                                                                  function (
                                                                    subitems,
                                                                    i
                                                                  ) {
                                                                    return [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          key: i,
                                                                          class:
                                                                            subitems.class,
                                                                          attrs:
                                                                            {
                                                                              cols: subitems.cols,
                                                                            },
                                                                        },
                                                                        [
                                                                          subitems.value ===
                                                                          "is_completed"
                                                                            ? [
                                                                                _vm
                                                                                  .productdata
                                                                                  .moduleTitle ===
                                                                                "message.layout.drawing"
                                                                                  ? _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "px-0 pr-2",
                                                                                        attrs:
                                                                                          {
                                                                                            dense:
                                                                                              "",
                                                                                            "hide-details":
                                                                                              "",
                                                                                            "item-text":
                                                                                              "text",
                                                                                            "item-value":
                                                                                              "value",
                                                                                            items:
                                                                                              subitems.items,
                                                                                            label:
                                                                                              "Status",
                                                                                            outlined:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.stopPropagation()
                                                                                            },
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.saveStatusUpdate(
                                                                                                _vm.productdata.list.indexOf(
                                                                                                  item
                                                                                                )
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              item[
                                                                                                subitems
                                                                                                  .value
                                                                                              ],
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  item,
                                                                                                  subitems.value,
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "item[subitems.value]",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : [
                                                                                      !_vm
                                                                                        .productdata
                                                                                        .itemHeaders
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              class:
                                                                                                subitems.spanClass,
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "strong",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "message.projects.status"
                                                                                                      )
                                                                                                    ) +
                                                                                                      ":"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      !_vm
                                                                                        .productdata
                                                                                        .itemHeaders
                                                                                        ? _c(
                                                                                            "br"
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          class:
                                                                                            subitems.spanClass,
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item[
                                                                                                subitems
                                                                                                  .value
                                                                                              ] ===
                                                                                                1
                                                                                                ? _vm.$t(
                                                                                                    "message.customModule.completed"
                                                                                                  )
                                                                                                : _vm.$t(
                                                                                                    "message.customModule.notCompleted"
                                                                                                  )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                              ]
                                                                            : subitems.isTranslatable
                                                                            ? [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      subitems.spanClass,
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          item[
                                                                                            subitems
                                                                                              .value
                                                                                          ]
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            : _c(
                                                                                "span",
                                                                                {
                                                                                  class:
                                                                                    subitems.spanClass,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                ]
                                              }
                                            ),
                                          ],
                                          _vm.productdata.actionSize
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0 pr-1 mt-3",
                                                  attrs: {
                                                    cols: _vm.productdata
                                                      .actionSize,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      staticClass: "mx-0",
                                                      attrs: {
                                                        row: "",
                                                        wrap: "",
                                                        "justify-end": "",
                                                      },
                                                    },
                                                    [
                                                      !_vm.productdata
                                                        .isChecklist &&
                                                      _vm.productdata
                                                        .hasDelete &&
                                                      _vm.checkActionPermission(
                                                        _vm.productdata.module,
                                                        _vm.CONSTANTS.DELETE
                                                      )
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                "max-height":
                                                                  "24",
                                                                "max-width":
                                                                  "24",
                                                                icon: "",
                                                                color: "error",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.deleteRecord(
                                                                      item.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-delete-circle"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        }
                      : {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-0 mt-2  overflow-x-hidden",
                                  attrs: { outlined: "", color: "#e8eaf6" },
                                  on: {
                                    click: function ($event) {
                                      _vm.checkActionPermission(
                                        _vm.productdata.module,
                                        _vm.CONSTANTS.EDIT
                                      )
                                        ? _vm.scrollListPayload.editHandler(
                                            item
                                          )
                                        : ""
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0 px-4 my-2" },
                                    [
                                      _vm._l(
                                        _vm.productdata.items,
                                        function (colValues, index) {
                                          return [
                                            _c(
                                              "v-row",
                                              {
                                                key: index,
                                                staticClass: "pt-1",
                                              },
                                              [
                                                _vm.productdata.itemHeaders &&
                                                _vm.productdata.itemHeaders
                                                  .length
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pa-0",
                                                        attrs: {
                                                          cols: !_vm.productdata
                                                            .hasDelete
                                                            ? "4"
                                                            : "3",
                                                        },
                                                      },
                                                      [
                                                        _vm.productdata
                                                          .itemHeaders[
                                                          index
                                                        ] ===
                                                          "message.projects.drawingGroup" &&
                                                        _vm.productdata
                                                          .advanceDrawingTypes
                                                          ? _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm
                                                                      .productdata
                                                                      .itemHeaders[
                                                                      index
                                                                    ]
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm.productdata
                                                              .itemHeaders[
                                                              index
                                                            ] !==
                                                            "message.projects.drawingGroup"
                                                          ? _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    _vm
                                                                      .productdata
                                                                      .itemHeaders[
                                                                      index
                                                                    ]
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-0",
                                                    class:
                                                      _vm.$t(
                                                        _vm.productdata
                                                          .moduleTitle
                                                      ) ===
                                                        _vm.$t(
                                                          "message.layout.drawing"
                                                        ) &&
                                                      item &&
                                                      item.is_completed === 5
                                                        ? "orange--text text--lighten-2"
                                                        : "",
                                                    attrs: {
                                                      cols: !_vm.productdata
                                                        .hasDelete
                                                        ? "8"
                                                        : "8",
                                                    },
                                                  },
                                                  [
                                                    colValues.value
                                                      ? [
                                                          colValues.isParagraph
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item[
                                                                          colValues
                                                                            .value
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mx-2",
                                                                  class:
                                                                    colValues.spanClass,
                                                                },
                                                                [
                                                                  colValues.label
                                                                    ? _c(
                                                                        "strong",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              colValues.label
                                                                            ) +
                                                                              ": "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  colValues.label
                                                                    ? _c("br")
                                                                    : _vm._e(),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item[
                                                                        colValues
                                                                          .value
                                                                      ]
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      : [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              class:
                                                                colValues.rowClass,
                                                            },
                                                            [
                                                              _vm._l(
                                                                colValues.subItems,
                                                                function (
                                                                  subitems,
                                                                  i
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        key: i,
                                                                        class:
                                                                          subitems.class,
                                                                        attrs: {
                                                                          cols: subitems.cols,
                                                                        },
                                                                      },
                                                                      [
                                                                        subitems.value ===
                                                                        "is_completed"
                                                                          ? [
                                                                              _vm
                                                                                .productdata
                                                                                .moduleTitle ===
                                                                              "message.layout.drawing"
                                                                                ? _c(
                                                                                    "v-select",
                                                                                    {
                                                                                      staticClass:
                                                                                        "px-0 pr-2",
                                                                                      attrs:
                                                                                        {
                                                                                          dense:
                                                                                            "",
                                                                                          "hide-details":
                                                                                            "",
                                                                                          "item-text":
                                                                                            "text",
                                                                                          "item-value":
                                                                                            "value",
                                                                                          items:
                                                                                            subitems.items,
                                                                                          label:
                                                                                            "Status",
                                                                                          outlined:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                          },
                                                                                        change:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.saveStatusUpdate(
                                                                                              _vm.productdata.list.indexOf(
                                                                                                item
                                                                                              )
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            item[
                                                                                              subitems
                                                                                                .value
                                                                                            ],
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                item,
                                                                                                subitems.value,
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "item[subitems.value]",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : [
                                                                                    !_vm
                                                                                      .productdata
                                                                                      .itemHeaders
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "mx-2",
                                                                                            class:
                                                                                              subitems.spanClass,
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "strong",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "message.projects.status"
                                                                                                    )
                                                                                                  ) +
                                                                                                    ":"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    !_vm
                                                                                      .productdata
                                                                                      .itemHeaders
                                                                                      ? _c(
                                                                                          "br"
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mx-2",
                                                                                        class:
                                                                                          subitems.spanClass,
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            item[
                                                                                              subitems
                                                                                                .value
                                                                                            ] ===
                                                                                              1
                                                                                              ? _vm.$t(
                                                                                                  "message.customModule.completed"
                                                                                                )
                                                                                              : _vm.$t(
                                                                                                  "message.customModule.notCompleted"
                                                                                                )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                            ]
                                                                          : subitems.isTranslatable
                                                                          ? [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-2",
                                                                                  class:
                                                                                    subitems.spanClass,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        item[
                                                                                          subitems
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          : _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-2",
                                                                                class:
                                                                                  subitems.spanClass,
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      item[
                                                                                        subitems
                                                                                          .value
                                                                                      ]
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ]
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              staticClass: "mx-0 mr-5",
                                              attrs: {
                                                row: "",
                                                wrap: "",
                                                "justify-end": "",
                                              },
                                            },
                                            [
                                              !_vm.productdata.isChecklist &&
                                              _vm.productdata.hasDelete &&
                                              _vm.checkActionPermission(
                                                _vm.productdata.module,
                                                _vm.CONSTANTS.DELETE
                                              )
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        color: "error",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.deleteRecord(
                                                            item.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { large: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-delete-circle"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                  ],
                  null,
                  true
                ),
              }),
              _c(
                "p",
                { staticClass: "text-center px-5 py-0" },
                [
                  _vm.productdata.loading
                    ? _c("v-progress-linear", {
                        attrs: {
                          color: "deep-purple accent-4",
                          indeterminate: "",
                          rounded: "",
                          height: "6",
                        },
                      })
                    : _vm._e(),
                  false
                    ? _c("v-progress-circular", {
                        staticClass: "mx-auto",
                        attrs: { indeterminate: "", color: "primary" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              !_vm.productdata.loading && _vm.productdata.list.length === 0
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-2",
                      attrs: { prominent: "", text: "", type: "info" },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { staticClass: "grow" }, [
                            _vm._v(_vm._s(_vm.$t("message.common.noResults"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.foundscrll
        ? _c("v-card-text", [
            _c("div", { staticClass: "founddata" }, [
              _vm._v(_vm._s(_vm.$t("message.common.no_data"))),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }